/* eslint-disable */

// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function (_, ns, handle) {
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function (_, ns, handle) {
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};

/****
  *************
  *************
    Lazy Loading Images, Videos, and iFrames
    for some reason this doesn't work when placed in common.js
  *************
  *************

  * For <img> tags
  *****************

  Add class .lazy and change src to data-src
  <img class="lazy" data-src="/media/homenew.jpg"/>

  * For inline CSS background images
  ************************************

  Add class .lazy to the div
  Remove any style="background-image:url('');background-size:cover;" etc
  Add custom data attributes:

  <div class="lazy" data-bgimage="<?php if($bgGradientOverlay) echo $bgGradientOverlay . ','; ?>url(<?php echo $bgImageURL; ?>)" data-bgsize="cover" data-bgposition="center" data-bgrepeat="no-repeat">

  * For CSS background images defined
  * in CSS files
  ************************************

  Add class .lazy background to div:

  <div class="my-bg-div lazy-background">

  Remove background image from CSS class and add it nested under a .visible class:

  .my-bg-div {
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
    &.visible {
      background-image('../../images/myimg.jpg');
    }
  }

  * For iFrames
  ************************************

  Add class .lazy to iframe
  Change src to data-src

  <iframe class="lazy" data-src="https://snazzymaps.com/embed/289457" style="border:none;"></iframe>

  * For Videos
  ************************************

  Add class .lazy to <video> tag
  Change src to data-src in <source> tag

  <video playsinline autoplay muted loop class="lazy d-none d-xl-block">
    <source data-src="/my-video.mp4" type="video/mp4">
  </video>

  * For Sliders
  ************************************

  Make sure Splide Intersection is imported at the top of common.js

  import { Intersection } from '@splidejs/splide-extension-intersection';

  Add the appropriate lazy classes the way you normally would.

  Update your slider syntax to use the Intersection Observer (this example uses a slider with <img data-src="/myimage.jpg"/> tags):

  var logoSliders = document.getElementsByClassName('rpb-logo-slider');
  for (var logoCount = 0; logoCount < logoSliders.length; logoCount++) {
      // mount a new slider with the Intersection observer
      var logoSplide = new Splide(logoSliders[logoCount]).mount({ Intersection });
      // on intersection, set the data-src of the slider img to the src
      logoSplide.on( 'intersection:in', function ( entry ) {
          $(entry.target).find('.logo-banner-img').each(function() {
              var logoAttr = $(this).attr('data-src');
              $(this).attr('src',logoAttr);
          });
      } );
  }
*****/

document.addEventListener('DOMContentLoaded', function () {
    const screenThreshold = 768; // Define screen width threshold

    // Function to directly apply the background image based on screen width
    function applyBgImageBasedOnScreenWidth() {
        document.querySelectorAll('.lazy').forEach(element => {
            const bgImageMedium = element.getAttribute('data-bgimage-medium');
            const bgImageFull = element.getAttribute('data-bgimage-full');
            const screenWidth = window.innerWidth;

            let bgImage = screenWidth < screenThreshold && bgImageMedium ? bgImageMedium : bgImageFull;
            if (bgImage) {
                // If a gradient overlay exists, prepend it to the bgImage URL
                const bgGradientOverlay = element.getAttribute('data-bggradient-overlay');
                if (bgGradientOverlay) {
                    bgImage = `${bgGradientOverlay}, url(${bgImage})`;
                } else {
                    bgImage = `url(${bgImage})`;
                }
                element.style.backgroundImage = bgImage;
            }

            // Apply other background properties if they exist
            const bgSize = element.getAttribute('data-bgsize');
            const bgPosition = element.getAttribute('data-bgposition');
            const bgRepeat = element.getAttribute('data-bgrepeat');

            if (bgSize) {
                element.style.backgroundSize = bgSize;
            }
            if (bgPosition) {
                element.style.backgroundPosition = bgPosition;
            }
            if (bgRepeat) {
                element.style.backgroundRepeat = bgRepeat;
            }
        });
    }

    if ('IntersectionObserver' in window) {
        let observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    let element = entry.target;

                    // Directly apply the background image for elements becoming visible
                    applyBgImageBasedOnScreenWidth(); // Call this to apply based on current screen width
                    element.classList.remove('lazy');
                    observer.unobserve(element);
                }
            });
        }, observerOptions);

        // Observe all elements with the 'lazy' class
        document.querySelectorAll('.lazy').forEach(el => {
            observer.observe(el);
        });

        // Reapply background images on resize to handle screen width changes
        window.addEventListener('resize', applyBgImageBasedOnScreenWidth);
    }

    // Initial application of background images
    applyBgImageBasedOnScreenWidth();
});


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import singleLocation from './routes/location';

/** Populate Router instance with DOM routes */
const routes = new Router({
    // All pages
    common,
    // Single location
    singleLocation,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
